<template>
    <div class="home-view">
        <b-container fluid>
            <b-row>
                <b-col />
                <b-col>
                    <div class="container">
                        <h1>Oops, something went wrong!</h1>
                        <p>An error has occurred. Please try again later.</p>
                        <p><a href="javascript:history.go('-1')">Click here</a> to return to the previous page.</p>
                    </div>
                </b-col>
                <b-col />
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    components: {}
};
</script>

<style>
.home-view .container {
    margin-top: 100px;
    background: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 12px #8274e833;
}

.home-view .container p:last-child {
    margin: 0;
}
</style>
